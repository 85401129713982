import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import ApplyProvider from "./Contexts/ApplyContext";
import BasicLayout from "./Layouts/BasicLayout";
import ErrorLayout from "./Layouts/ErrorLayout";
import ScrollToTop from "./Layouts/ScrollToTop";
import Apply from "./Pages/Apply";
import NotFound from "./Pages/NotFound";

function App() {
  return (
    <Router basename="/">
      <ApplyProvider>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<BasicLayout />}>
            <Route index element={<Apply />} />
          </Route>
          <Route path="/" element={<ErrorLayout />}>
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </ApplyProvider>
    </Router>
  );
}

export default App;
