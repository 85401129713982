import React from "react";
import SuccessImg from "../../Assets/Images/success-img.png";

function ApplySuccess() {
  return (
    <div className="form-message">
      <div className="form-message__container containers">
        <img src={SuccessImg} alt="Application Successful" />
        <h3>Your application has been received</h3>
        <p>
          Thank you for taking out time to complete your application. An email will be sent to your registered email.
        </p>
        <a href="https://reworkacademy.co/">Back to Home Page</a>
      </div>
    </div>
  );
}

export default ApplySuccess;
