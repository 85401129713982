import React, { useContext, useState } from "react";
import FormInputs from "./FormInputs";
import { ApplyContext } from "../../Contexts/ApplyContext";
import axios from "axios";
import ApplySuccess from "./ApplySuccess";

function ApplyForm() {
  const { title, page, setPage, data, saveData, disablePrev, hidePrev, hideNext, hideSubmit, setErr, err, savePage1, savePage2, success, setSuccess } =
    useContext(ApplyContext);
  const [submitErr, setSubmitErr] = useState(false);
  const url = process.env.REACT_APP_URL;

  const handlePrev = () => {
    setPage((prev) => prev - 1);
  };
  const handleNext = () => {
    if (!savePage1 && page === 0) setErr({ ...err, err1: true });
    if (!savePage2 && page === 1) setErr({ ...err, err2: true });
    if (page === Object.keys(title).length - 1 || (page === 0 && !savePage1) || (page === 1 && !savePage2)) {
      return;
    }
    setPage((prev) => prev + 1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!saveData && Object.keys(title).length - 1) {
      setErr({ ...err, err3: true });
      return;
    }
    const sendData = async () => {
      setSubmitErr(true);
      const req = await axios.post(`${url}/interests`, data);
      const resp = await req.data;
      if (resp.code !== 200) {
        return;
      }
      setSubmitErr(false);
      setSuccess(true);
    };
    sendData();
  };

  const content =
    success === true ? (
      <ApplySuccess />
    ) : (
      <div className="apply__form">
        <form onSubmit={handleSubmit}>
          <div className="apply__form-header">
            <h3>{title[page]}</h3>
          </div>
          <FormInputs />
          <div className="apply__btns">
            <button type="button" disabled={disablePrev} onClick={handlePrev} className={`link__btn link__btn-outline-blue ${hidePrev}`}>
              Back
            </button>
            <button type="button" onClick={handleNext} className={`link__btn link__btn-bg-blue ${hideNext}`}>
              Next
            </button>
            <button type="button" className={`link__btn link__btn-bg-blue ${hideSubmit}`} onClick={handleSubmit}>
              {submitErr ? "Please wait..." : "Submit"}
            </button>
          </div>
        </form>
      </div>
    );
  return content;
}

export default ApplyForm;
