import React from "react";
import AcademyLogo from "../../Assets/Images/logo2.png";
import ApplyProgress from "./ApplyProgress";

const ApplyBanner = () => {
  return (
    <div className="apply__banner">
      <div className="apply__banner-content">
        <a href="https://reworkacademy.co/" className="appply__banner-header">
          <img src={AcademyLogo} alt="Logo" className="apply__logo1" />
        </a>
        <div className="apply__banner-text">
          <h2>Join our next Batch of learners!</h2>
          <p>Apply and get the required skills</p>
        </div>
        <ApplyProgress />
      </div>
    </div>
  );
};
export default ApplyBanner;
