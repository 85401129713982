import React from "react";
import { useContext } from "react";
import { ApplyContext } from "../../Contexts/ApplyContext";

function ContactDetails() {
  const { data, handleChange, err } = useContext(ApplyContext);

  return (
    <>
      <div className="apply__form-group apply__form-sub-group">
        <div className="apply__form-sub">
          <label htmlFor="first_name">First name*</label>
          <input
            type="text"
            name="first_name"
            value={data.first_name}
            onChange={handleChange}
            className={err.err1 && data.first_name === "" ? "err-input" : null}
          />
        </div>
        <div className="apply__form-sub">
          <label htmlFor="last_name">Last name*</label>
          <input
            type="text"
            name="last_name"
            value={data.last_name}
            onChange={handleChange}
            className={err.err1 && data.last_name === "" ? "err-input" : null}
          />
        </div>
      </div>
      <div className="apply__form-group">
        <label htmlFor="other_name">Other name</label>
        <input type="text" name="other_name" value={data.other_name} onChange={handleChange} />
      </div>
      <div className="apply__form-group apply__form-sub-group">
        <div className="apply__form-sub">
          <label htmlFor="birth_date">Date of birth*</label>
          <input
            type="date"
            name="birth_date"
            value={data.birth_date}
            onChange={handleChange}
            className={err.err1 && data.birth_date === "" ? "err-input" : null}
          />
        </div>
        <div className="apply__form-sub">
          <label htmlFor="country">Country*</label>
          <input type="text" name="country" value={data.country} onChange={handleChange} className={err.err1 && data.country === "" ? "err-input" : null} />
        </div>
      </div>
      <div className="apply__form-group apply__form-sub-group">
        <div className="apply__form-sub">
          <label htmlFor="state">State of Residence*</label>
          <input type="text" name="state" value={data.state} onChange={handleChange} className={err.err1 && data.state === "" ? "err-input" : null} />
        </div>
        <div className="apply__form-sub">
          <label htmlFor="gender" className={err.err1 && data.gender === "" ? "err-radio" : null}>
            Gender*
          </label>
          <div className="apply__form-sub-group apply__input-group">
            <div className="apply__form-radio">
              <input type="radio" name="gender" value="Male" checked={data.gender === "Male" ? true : false} onChange={handleChange} />
              <span>Male</span>
            </div>
            <div className="apply__form-radio">
              <input type="radio" name="gender" value="Female" checked={data.gender === "Female" ? true : false} onChange={handleChange} />
              <span>Female</span>
            </div>
          </div>
        </div>
      </div>
      <div className="apply__form-group">
        <label htmlFor="phone">Phone number*</label>
        <input type="tel" name="phone" value={data.phone} onChange={handleChange} className={err.err1 && data.phone === "" ? "err-input" : null} />
      </div>
      <div className="apply__form-group">
        <label htmlFor="email">Email*</label>
        <input type="email" name="email" value={data.email} onChange={handleChange} className={err.err1 && data.email === "" ? "err-input" : null} />
      </div>
    </>
  );
}

export default ContactDetails;
