import React, { useContext } from "react";
import { ApplyContext } from "../../Contexts/ApplyContext";

function ApplyProgress() {
  const { page, title } = useContext(ApplyContext);

  const interval = 100 / Object.keys(title).length;
  const progress = ((page + 1) * interval).toFixed(2);
  const steps = Object.keys(title).map((step, i) => {
    return (
      <div key={i} className="ap__progress-step">
        {title[i]}
      </div>
    );
  });

  return (
    <div className="apply__banner-footer">
      <div className="ap__progress-steps">{steps}</div>
      <progress className="ap__progress" max="100" value={progress}></progress>
    </div>
  );
}

export default ApplyProgress;
