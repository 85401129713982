import React from "react";
import Logo from "../Assets/Images/logo.png";
import "../Assets/Styles/Footer.css";
import { IoHeartSharp } from "react-icons/io5";
import { DataContext } from "../Contexts/ApplyContext";

function Footer() {
  const year = new Date().getFullYear();
  const { courses } = DataContext();
  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__contents">
          <div className="footer__content footer__content1">
            <div className="footer-content__img">
              <a href="https://reworkacademy.co/">
                <img src={Logo} alt="Logo" />
              </a>
            </div>
            <div className="footer-content__sub">
              <h3>Address</h3>
              <p>House 5, block C, Aknaton estate, Da Akun Adawel street, off Dunamis road, by Old Federal Secretariat, Durumi, Area 1, Abuja</p>
            </div>
            <div className="footer-content__sub">
              <h3>Contact</h3>
              <a href="tel:+234 703 561 6891" target={"_blank"} rel="noreferrer">
                +234 703 561 6891
              </a>
              <a href="mailto:enquiry@reworkacademy.co">enquiry@reworkacademy.co</a>
            </div>
          </div>
          <div className="footer__content footer__content2">
            <h3>Courses</h3>
            <ul className="footer-content__list">
              {courses
                ? courses.map((co) => (
                    <li key={co.id}>
                      <a href={`https://reworkacademy.co/courses${co.route}`}>{co.name}</a>
                    </li>
                  ))
                : null}
            </ul>
          </div>
          <div className="footer__content footer__content3">
            <h3>Company</h3>
            <ul className="footer-content__list">
              <li>
                <a href="https://reworkacademy.co/why-rework-academy">Why Rework Academy</a>
              </li>
              <li>
                <a href="https://reworkacademy.co/contact-us">Contact us</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-sub__contents">
          <p>{year} Rework Academy. All rights reserved</p>
          <div>
            <p className="footer__heart-cont">
              Made with <IoHeartSharp className="footer__heart-icon" /> from
              <a href="https://reworkacademy.co/" target="_blank" rel="noopener noreferrer">
                Rework Academy
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
