import React from "react";
import { useContext } from "react";
import "../Assets/Styles/Apply.css";
import ApplyBanner from "../Components/apply/ApplyBanner";
import ApplyForm from "../Components/apply/ApplyForm";
import { ApplyContext } from "../Contexts/ApplyContext";

function Apply() {
  const { success } = useContext(ApplyContext);

  return (
    <div className="apply">
      <div className="apply__containers">
        {success ? (
          <ApplyForm />
        ) : (
          <div className="apply__grid">
            <ApplyBanner />
            <ApplyForm />
          </div>
        )}
      </div>
    </div>
  );
}

export default Apply;
