import React, { useContext } from "react";
import { ApplyContext } from "../../Contexts/ApplyContext";
import CareerSkill from "./CareerSkill";
import ContactDetails from "./ContactDetails";
import CourseOption from "./CourseOption";

const FormInputs = () => {
  const { page } = useContext(ApplyContext);

  const display = {
    0: <ContactDetails />,
    1: <CareerSkill />,
    2: <CourseOption />,
  };

  const content = <>{display[page]}</>;
  return content;
};

export default FormInputs;
