import React, { useContext } from "react";
import { ApplyContext } from "../../Contexts/ApplyContext";

function CareerSkill() {
  const { data, handleChange, err, courses } = useContext(ApplyContext);

  return (
    <>
      <div className="apply__form-group">
        <label htmlFor="address">Address*</label>
        <input type="text" name="address" value={data.address} onChange={handleChange} className={err.err2 && data.address === "" ? "err-input" : null} />
      </div>
      <div className="apply__form-group">
        <label htmlFor="occupation">Occupation*</label>
        <select name="occupation" value={data.occupation} onChange={handleChange} className={err.err2 && data.occupation === "" ? "err-input" : null}>
          <option value="">Select</option>
          <option value="Student">Student</option>
          <option value="Unemployed">Unemployed</option>
          <option value="Employed (Part time)">Employed (Part time)</option>
          <option value="Employee(Full time)">Employee(Full time)</option>
        </select>
      </div>
      <div className="apply__form-group">
        <label htmlFor="course">Interested Course*</label>
        <select
          name="course_manager_id"
          value={data.course_manager_id}
          onChange={handleChange}
          className={err.err2 && data.course_manager_id === "" ? "err-input" : null}>
          <option value="">Select</option>
          {courses.map((course) => (
            <option key={course.id} value={course.id}>
              {course.name}
            </option>
          ))}
        </select>
      </div>
      <div className="apply__form-group">
        <label htmlFor="education">Highest Education Qualification*</label>
        <select name="education" value={data.education} onChange={handleChange} className={err.err2 && data.education === "" ? "err-input" : null}>
          <option value="">Select</option>
          <option value="Primary School Certificate">Primary School Certificate</option>
          <option value="Secondary School Certificate (SSCE)">Secondary School Certificate (SSCE)</option>
          <option value="Diploma / Technical Certificate">Diploma / Technical Certificate</option>
          <option value="Graduate (HND / B.ED / BSc / B.Tech/ B.A / B.Law)">Graduate (HND / B.ED / BSc / B.Tech/ B.A / B.Law)</option>
          <option value="Postgraduate (MSc / M.A / M.Eng)">Postgraduate (MSc / M.A / M.Eng)</option>
          <option value="Doctorate (PhD)">Doctorate (PhD)</option>
        </select>
      </div>
      <div className="apply__form-group">
        <label htmlFor="program_level">Programming Knowledge*</label>
        <select name="program_level" value={data.program_level} onChange={handleChange} className={err.err2 && data.program_level === "" ? "err-input" : null}>
          <option value="">Select</option>
          <option value="Novice">Novice</option>
          <option value="Beginner">Beginner</option>
          <option value="Intermediate">Intermediate</option>
          <option value="Professional">Professional</option>
        </select>
      </div>
    </>
  );
}

export default CareerSkill;
