import React from "react";
import ErrImg from "../Assets/Images/404-Error-Page2.png";
import "../Assets/Styles/NotFound.css";

function NotFound() {
  return (
    <div className="errors">
      <div className="containers err__content">
        <img src={ErrImg} alt="Not Found" />
        <h2>No Results Found</h2>
        <p>
          The page you requested could not be found. Try refining your search, or use the navigation below to locate the
          home page.
        </p>
        <a href="https://reworkacademy.co/">Go back home</a>
      </div>
    </div>
  );
}

export default NotFound;
