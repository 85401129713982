import React, { useContext } from "react";
import { ApplyContext } from "../../Contexts/ApplyContext";

function CourseOption() {
  const { data, handleChange, err, assDate } = useContext(ApplyContext);

  const options =
    data?.course_manager_id === "5vc6wbdhpkz6rnud8" || data?.course_manager_id === "5vc6wbdhpkz6rxwk6" ? (
      <>
        <option value="Weekday">Weekday (Monday - Friday: 9am - 1pm)</option>
      </>
    ) : (
      <>
        <option value="Weekday">Weekday (Monday - Friday: 9am - 1pm)</option>
        <option value="Weekend">Weekend (Saturday(10am - 4pm)) & (Sunday (1pm - 5pm))</option>
      </>
    );

  const assDateOpt = data?.session.includes("Weekday")
    ? assDate.weekday.date.map((dat, i) => (
        <option value={dat} key={i}>
          {dat}
        </option>
      ))
    : data?.session.includes("Weekend")
    ? assDate.weekend.date.map((data, i) => (
        <option value={data} key={i}>
          {data}
        </option>
      ))
    : null;

  const assTimeOpt = data?.session.includes("Weekday")
    ? assDate.weekday.time.map((time1, i) => (
        <option value={time1} key={i}>
          {time1}
        </option>
      ))
    : data?.session.includes("Weekend")
    ? assDate.weekend.time.map((time2, i) => (
        <option value={time2} key={i}>
          {time2}
        </option>
      ))
    : null;

  const learning_modes =
    data?.session === "Weekday" ? (
      <>
        <option value="Virtual">Virtual</option>
        <option value="Physical">Physical</option>
      </>
    ) : (
      <>
        <option value="Physical">Physical</option>
      </>
    );

  const asessment_session = data?.course_manager_id === "5vc6wwb8plumpt5qh" || data?.course_manager_id === "5vc6wcus2kzefvaxn" ? false : true;

  return (
    <>
      <div className="apply__form-group">
        <label htmlFor="session">Session Option*</label>
        <select name="session" value={data.session} onChange={handleChange} className={err.err3 && data.session === "" ? "err-input" : null}>
          <option value="">Select</option>
          {options}
        </select>
      </div>
      <div className="apply__form-group">
        <label htmlFor="learning_mode">Mode of Learning / Class Option*</label>
        <select name="learning_mode" value={data.learning_mode} onChange={handleChange} className={err.err3 && data.learning_mode === "" ? "err-input" : null}>
          <option value="">Select</option>
          {learning_modes}
        </select>
      </div>
      {asessment_session ? (
        <div className="apply__form-group apply__form-sub-group">
          <div className="apply__form-sub">
            <label htmlFor="assessment_date">Assessment Date</label>
            <select
              name="assessment_date"
              value={data.assessment_date}
              onChange={handleChange}
              className={err.err3 && data.assessment_date === "" ? "err-input" : null}>
              <option value="">Select</option>
              {data?.session && assDateOpt}
            </select>
          </div>
          <div className="apply__form-sub">
            <label htmlFor="assessment_time">Assessment Time</label>
            <select
              name="assessment_time"
              value={data.assessment_time}
              onChange={handleChange}
              className={err.err3 && data.assessment_time === "" ? "err-input" : null}>
              <option value="">Select</option>
              {data?.session && assTimeOpt}
            </select>
          </div>
        </div>
      ) : null}
      <div className="apply__form-group">
        <label htmlFor="hear_about_us">How did you hear about us*</label>
        <input
          type="text"
          name="hear_about_us"
          value={data.hear_about_us}
          onChange={handleChange}
          className={err.err3 && data.hear_about_us === "" ? "err-input" : null}
        />
      </div>
      <div className="apply__form-group">
        <label htmlFor="ref_code">Referal Code(if any)</label>
        <input type="text" name="ref_code" value={data.ref_code} onChange={handleChange} />
      </div>
      <div className="apply__form-group">
        <label htmlFor="more_about_you">Why do you want to join the Progam*</label>
        <textarea
          name="more_about_you"
          value={data.more_about_you}
          onChange={handleChange}
          className={err.err3 && data.more_about_you === "" ? "err-input" : null}></textarea>
      </div>
    </>
  );
}

export default CourseOption;
