import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ApplyContext } from "../Contexts/ApplyContext";

export default function ScrollToTop() {
  const { pathname } = useLocation();
  const { success, page } = useContext(ApplyContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname, success, page]);

  return null;
}
